import React from "react"
import { useTranslation } from "react-i18next"

import { Heading } from "~/components/Heading/Heading"
import { Raw } from "~/components/raw"
import { Theme } from "~/components/Theme/Theme"

import styles from "./index.module.css"

export const ErrorPageNotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Theme className={styles.Wrapper} theme="dark">
      <div className={styles.Inner}>
        <div className={styles.Container}>
          <Heading className={styles.Title} like="h1">
            <Raw html={t("error-page-not-found.Title")} />
          </Heading>

          <div
            className={styles.Description}
            dangerouslySetInnerHTML={{
              __html: t("error-page-not-found.Description"),
            }}
          />

          <div className={styles.Menu}>
            <div className={styles.MenuItem}>
              <a dangerouslySetInnerHTML={{ __html: t("page.home") }} href="/" />
            </div>
            <div className={styles.MenuItem}>
              <a dangerouslySetInnerHTML={{ __html: t("page.about") }} href="/about" />
            </div>
            <div className={styles.MenuItem}>
              <a dangerouslySetInnerHTML={{ __html: t("page.press") }} href="/press" />
            </div>
            <div className={styles.MenuItem}>
              <a
                dangerouslySetInnerHTML={{ __html: t("page.investors-and-shareholders") }}
                href="/investors-and-shareholders"
              />
            </div>
          </div>
        </div>
      </div>
    </Theme>
  )
}
