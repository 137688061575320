/* eslint-disable complexity */
import "../../sentry"

import { NextPageContext } from "next"
import App, { AppContext, AppInitialProps } from "next/app"
import React from "react"

import { ApiContextProvider, getApiHost } from "~/api"
import { BreadcrumbsContextProvider } from "~/components/Breadcrumbs/BreadcrumbsProvider"

import i18next, { detectLang, Lang } from "../i18n"
import Error from "./_error"

type Props = {
  lang: Lang
  apiHost: string
  slug: string
  isErrorNotFound: boolean
}

// const redirect = (ctx: NextPageContext): boolean => {
//   if (!ctx.req) {
//     return false
//   }

//   const host = ctx.req.headers.host?.includes("sistema.com") ? "sistema.com" : "sistema.ru"
//   const url = `${ctx.req.url?.trim()}`.replace(/\/$/, "")
//   const key = `${host}${url}`

//   if (redirects[key]) {
//     ctx.res?.writeHead(301, { Location: `https://${redirects[key]}` })
//     ctx.res?.end()
//     return true
//   }

//   return false
// }

const displayNotFoundError = (ctx: NextPageContext, pageProps: any): boolean => {
  if (!ctx.req) {
    return false
  }

  if (Object.keys(pageProps).length === 0) {
    return false
  }

  const isAssets =
    ctx.req.url?.slice(1).split("/")[0] === "assets" &&
    Object.keys(pageProps.apiContext).find((value) => value === "/assets/element") !== undefined
  const isFunds =
    ctx.req.url?.slice(1).split("/")[0] === "funds" &&
    Object.keys(pageProps.apiContext).find((value) => value === "/fund/element") !== undefined
  const isPressPost =
    ctx.req.url?.slice(1).split("/")[0] === "press" &&
    Object.keys(pageProps.apiContext).find((value) => value === "/news/element") !== undefined

  if (isAssets) {
    const res = pageProps.apiContext["/assets/element"].response
    const isId = Object.keys(res).find((value) => value === "id") !== undefined

    if (!isId) {
      ctx.res!.statusCode = 404
      return true
    }
  }

  if (isFunds) {
    const res = pageProps.apiContext["/fund/element"].response
    const isId = res.id !== null

    if (!isId) {
      ctx.res!.statusCode = 404
      return true
    }
  }

  if (isPressPost) {
    const res = pageProps.apiContext["/news/element"].response
    const isName = res.name !== null

    if (!isName) {
      ctx.res!.statusCode = 404
      return true
    }
  }

  return false
}

class MyApp extends App<Props> {
  static async getInitialProps(appContext: AppContext): Promise<AppInitialProps & Props> {
    const { pageProps } = await App.getInitialProps(appContext)
    const lang = detectLang(appContext.ctx?.req?.headers.host)
    const slug = appContext.ctx.query.slug
    const apiHost = getApiHost(appContext.ctx.req?.headers.host || "")
    const isErrorNotFound = displayNotFoundError(appContext.ctx, pageProps)

    // redirect(appContext.ctx)

    displayNotFoundError(appContext.ctx, pageProps)

    return { pageProps, lang, apiHost, slug: slug as string, isErrorNotFound }
  }

  constructor(props: Props) {
    super(props as never)
    i18next(props.lang || detectLang())
  }

  render(): JSX.Element {
    const {
      Component,
      pageProps: { apiContext, crumbs, slug, ...pageProps },
      apiHost,
      isErrorNotFound,
    } = this.props

    return (
      <ApiContextProvider value={{ apiData: apiContext, slug, apiHost }}>
        {isErrorNotFound ? (
          <Error />
        ) : (
          <BreadcrumbsContextProvider value={crumbs}>
            <Component {...pageProps} />
          </BreadcrumbsContextProvider>
        )}
      </ApiContextProvider>
    )
  }
}

export default MyApp
