import { browserTracingIntegration } from "@sentry/browser"
import * as Sentry from "@sentry/node"
// eslint-disable-next-line import/default
import get from "lodash.get"

const isBrowser = (): boolean => typeof window !== "undefined"

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [browserTracingIntegration()],
  environment: process.env.SENTRY_ENVIRONMENT || "development",
  release: process.env.SENTRY_ENVIRONMENT || "development",
  enabled: process.env.NODE_ENV !== "test",
  // eslint-disable-next-line unicorn/no-zero-fractions
  tracesSampleRate: 1.0,
  debug: true,
  beforeSend: (event) => {
    console.log("Отправляемое событие в Sentry:", event)
    console.log("Sentry DSN:", process.env.SENTRY_DSN)
    return event
  },
})

if (!process.env.SENTRY_DSN && process.env.NODE_ENV !== "test") {
  console.error("Sentry DSN is not defined")
}

// Универсальный метод для настройки контекста запроса
export const configureReq = (req: any): void => {
  Sentry.withScope((scope) => {
    scope.setTag("runtimeEngine", isBrowser() ? "browser" : "server") // Добавляем тег, указывающий среду выполнения
    scope.setTag("host", get(req, "headers.host", "unknown")) // Хост запроса
    scope.setContext("query", get(req, "query", {})) // Параметры запроса
    scope.setContext("headers", get(req, "headers", {})) // Заголовки
    scope.setContext("cookies", get(req, "cookies", {})) // Куки
    scope.setContext("body", get(req, "body", {})) // Тело запроса
  })
}

export default Sentry
