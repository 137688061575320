import { createContext, useContext } from "react"

export type Crumb = {
  name: string
  href?: string
}

const Context = createContext<Crumb[]>([])

export const BreadcrumbsContextProvider = Context.Provider

export const useBreadcrumbsContext = (): Crumb[] => {
  const context = useContext(Context)
  return context || []
}
