import React from "react"
import { useTranslation } from "react-i18next"

import { ErrorPageNotFound } from "~/components/ErrorPageNotFound/ErrorPageNotFound"
import { Layout } from "~/components/Layout/Layout"

const Error: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Layout headerTheme="onDark" title={t("page.error-page-not-found")}>
      <ErrorPageNotFound />
    </Layout>
  )
}

export default Error
